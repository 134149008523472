<template>
  <section
    id="products"
  >
    <core-jumbotron-mobile />
    <heading class="hidden-screen-only">
      Products
    </heading>
    <v-card
      flat
      class="mb-10"
    >
      <v-toolbar
        light
        flat
      >
        <v-select

          v-model="category"
          :value="category"
          :items="selectItems"
          return-object
          filled
          class="hidden-md-and-up mt-5  text-uppercase"
          @change="setTab"
        />
        <v-tabs
          v-model="tabs"
          grow
          show-arrows="true"
          background-color="transparent"
          centered
          class="hidden-sm-and-down"
        >
          <v-tab

            v-for="n in categories"
            :key="n"
            class="text-uppercase"
          >
            {{ n }}
          </v-tab>
        </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="tabs">
        <v-tab-item
          v-for="(category, i) in categories"
          :key="i"
        >
          <v-container
            fluid
            class="elevation-0"
          >
            <v-row v-if="!products[category].length">
              <v-col

                class="text-center"
                cols="12"
              >
                <h2>{{ $t('products.coming_soon') }}</h2>
              </v-col>
            </v-row>
            <v-row v-if="products[category]">
              <v-col

                v-for="(product,ii) in products[category]"

                :key="ii"
                cols="12"
                sm="6"
                md="4"
                lg="4"
              >
                <v-card
                  v-if="product.status!=false"
                  class="mx-auto text-center"
                  :height="isMobile()?200:300"

                  :to="{ name: 'product', params: {lang:$i18n.locale, company: product.company,product:product.id },query:{search:true}}"
                >
                  <v-img
                    class="white--text align-end text-no-wrap "
                    :src="product.image"
                    :height="isMobile()?200:300"
                  >
                    <v-card-title
                      v-if="isMobile()"
                      style="background-color:  rgba(0,117,131,.8);"
                      light
                      class="title justify-center  pa-0"
                    >
                      <small>{{ product.name | truncate(30) }}</small>
                    </v-card-title>
                    <v-card-title
                      v-if="!isMobile()"
                      style="background-color:  rgba(0,117,131,.8)  ;"
                      light
                      class="title justify-center  text-truncate"
                    >
                      {{ product.name | truncate(30) }}
                    </v-card-title>
                  </v-img>

                  <v-img
                    v-if="false"
                    class="ma-auto"
                    aspect-ratio="2"

                    width="75%"
                    :src="companies[product.company].logo"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </section>
</template>

<script>
  import i18n from '../i18n'

  export default {
    metaInfo: {
      title: i18n.t('menu.products'),
    },
    components: {
      Heading: () => import('@/components/Heading'),
      CoreJumbotronMobile: () => import('@/components/core/JumbotronMobile'),
    },

    data: () => ({
      category: null,
      selectItems: [],
      companies: null,
      english_categories: null,
      projects: null,
      error: null,
      loading: false,
      categories: null,
      lang: i18n.locale,
      tabs: 3,
      windowWidth: window.innerWidth,
    }),
    watch: {
      // call again the method if the route changes
      $route: 'fetchData',

    },
    mounted () {
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.windowWidth = window.innerWidth
        })
      })
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetchData()
    },
    methods: {
      isMobile () {
        if (this.windowWidth <= 960) {
          return true
        } else {
          return false
        }
      },
      setTab () {
        if (this.category == null) {
          this.selectItems.forEach(value => {
            if (value.value === this.$route.query.category.replace(/-/g, ' ').replace(/and/g, '&')) {
              this.category = value
            }
          })
        }

        const cat = this.category.value

        this.tabs = this.english_categories.findIndex(function (value) { return value.toLowerCase() === cat.replace(/-/g, ' ').replace(/and/g, '&') })
      },
      fetchData () {
        this.error = this.post = null
        this.loading = true

        // fetch english categories info
        fetch('/api/en/products.json')
          .then(r => r.json())
          .then(json => {
            this.english_categories = Object.keys(json)
            if (!this.$route.query.category) {
              this.category = { value: this.english_categories[3], text: i18n.t('home.harvest') }
            }
            // fetch product info
            fetch('/api/' + i18n.locale + '/products.json')
              .then(r => r.json())
              .then(json => {
                      this.loading = false
                      this.products = json
                      this.categories = Object.keys(json)
                      this.english_categories.forEach((key, i) => {
                        const cat = this.categories[i]
                        this.selectItems.push({ text: cat.toUpperCase(), value: key })
                      })

                      this.setTab()
                    },
                    response => {
                      console.log('Error loading json:', response)
                    })

            // fetch company info
            fetch('/api/' + i18n.locale + '/companies.json')
              .then(r => r.json())
              .then(json => {
                      this.loading = false

                      this.companies = json
                    },
                    response => {
                      console.log('Error loading json:', response)
                    })
          })
      },
    },
  }
</script>

<style lang="sass">
  #projects
    .v-slide-group__content
      justify-content: center
</style>
